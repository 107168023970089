export const dict = {
    se: {
      aiClassifiedThisAs: "AI klassificerade som",
      agree: "Instämmer",
      annotate: "Annotera",
      annotation: "Annotering",
      annotationTask: "Annoteringsuppgift",
      box: "Låd-etikett",
      config: "Konfiguration",
      dashboard: "Översikt",
      datasetsToAnnotate: "Dataset att annotera",
      delete: "Radera",
      details: "Detaljer",
      disagree: "Instämmer inte",
      flagForReview: "Flagga för genomgång",
      inspect: "Inspektera",
      inspection: "Inspektion",
      last60mins: "Senaste 60 min",
      loading: "Laddar",
      measurement: "Mätvärde",
      name: "Namn",
      nDefects: "# Defekter",
      newInspection: "Ny inspektion",
      nOK: "# OK",
      nUnclassified: "# Oklassificerade",
      overriddenClassification: "Överriden klassifikation",
      overrideClassification: "Överrid klassifikation",
      settings: "Inställningar",
      signOut: "Logga ut",
      singleLabel: "Enkel-etikett",
      startInspection: "Starta inspektion",
      service: "Service",
      statistics: "Statistik",
      type: "Typ",
      waiting: "Väntar",
      yourProfile: "Din profil",
    },
  };
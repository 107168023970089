import {
  Accessor,
  createEffect,
  createSignal,
  For,
  Setter,
  Show,
} from "solid-js";
import {
  AnnotationTypes,
  GalleryItems,
  GetCompleteDataset,
} from "../../../helpers/types";
import { CopyIcon } from "../../../components/Icons";

interface ImageModalProps {
  setShowModal: Setter<boolean>;
  index: Accessor<number>;
  setCurrentIndex: Setter<number>;
  images: GalleryItems | undefined;
  dataset: Accessor<GetCompleteDataset | undefined>;
}

export default function ImageModal(props: ImageModalProps) {
  const isIndexValid = (index: number) => {
    if (
      props.images !== undefined &&
      index >= 0 &&
      index < props.images?.length
    ) {
      return true;
    }
    return false;
  };

  const [endOfLeftIndex, setEndofLeftIndex] = createSignal<boolean>(false);
  const [endOfRightIndex, setEndofRightIndex] = createSignal<boolean>(false);

  createEffect(() => {
    if (props.index() === 0) {
      setEndofLeftIndex(true);
    } else {
      setEndofLeftIndex(false);
    }
  });

  createEffect(() => {
    if (
      props.images?.length !== undefined &&
      props.index() === props.images?.length - 1
    ) {
      setEndofRightIndex(true);
    } else {
      setEndofRightIndex(false);
    }
  });

  let magnifierRef!: HTMLDivElement;
  let imageRef!: HTMLImageElement;
  const MAGNIFIER_SIZE = 256;

  const hoverImage = (e: any) => {
    const sz = Math.round(MAGNIFIER_SIZE / 2);
    const rect = imageRef.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const xpx = Math.round((x / rect.width) * imageRef.naturalWidth * 2);
    const ypx = Math.round((y / rect.height) * imageRef.naturalHeight * 2);

    magnifierRef.style.left = `${e.clientX - sz}px`;
    magnifierRef.style.top = `${e.clientY - sz}px`;
    magnifierRef.style.backgroundPosition = `${-xpx + sz}px ${-ypx + sz}px`;
    magnifierRef.style.backgroundSize = `${imageRef.naturalWidth * 2}px ${
      imageRef.naturalHeight * 2
    }px`;
  };

  const enterImage = () => {
    magnifierRef.style.display = "block";
  };

  const leaveImage = () => {
    magnifierRef.style.display = "none";
  };

  return (
    <div class="fixed inset-0 z-40 flex items-center justify-center bg-black/70">
      <div
        class="absolute bg-no-repeat border-4 border-black cursor-none h-64 hidden pointer-events-none rounded-xl w-64 z-50"
        id="magnifier"
        ref={magnifierRef}
        style={{
          "background-image":
            "url(" + props.images?.[props.index()].largeURL + ")",
        }}
      />
      <div
        class="absolute inset-0 cursor-pointer"
        aria-hidden="true"
        onClick={() => props.setShowModal(false)}
      />

      <div class="flex flex-row items-center">
        <button
          class="-translate-y-1/2 text-white  bg-black/50 m-2 py-5 px-2 rounded-md"
          classList={{ "opacity-40 cursor-default": endOfLeftIndex() }}
          onClick={() => {
            if (isIndexValid(props.index() - 1)) {
              props.setCurrentIndex(props.index() - 1);
            } else {
              setEndofLeftIndex(true);
            }
          }}
        >
          ◀
        </button>

        <div class="relative flex bg-white rounded-lg overflow-hidden shadow-lg h-[83vh]">
          <Show when={isIndexValid(props.index())}>
            <div class="flex-1 flex relative bg-gray-500 items-center">
              <img
                onMouseMove={hoverImage}
                onMouseEnter={enterImage}
                onMouseLeave={leaveImage}
                ref={imageRef}
                src={props.images?.[props.index()].largeURL}
                alt={props.images?.[props.index()].name}
                class="h-auto w-full max-h-[90vh] object-contain"
              />
            </div>

            <div class="flex flex-col p-4 bg-gray-500 w-72">
              <div class="flex flex-col">
                <h4 class="text-xs uppercase font-black text-nl-gray-dark-300">
                  Image
                </h4>
                <p class="my-1 text-sm text-white">
                  {props.images?.[props.index()].name}
                </p>
              </div>
              <h4 class="text-xs uppercase font-black text-nl-gray-dark-300">
                ID{" "}
                <a
                  onClick={() =>
                    navigator.clipboard.writeText(
                      props.images?.[props.index()].id || "ID not found",
                    )
                  }
                  class="cursor-pointer active:animate-ping text-gray-400 active:text-gray-800 m-1"
                >
                  <CopyIcon />
                </a>
              </h4>
              <p class="my-1 text-sm text-white">
                {props.images?.[props.index()].id}{" "}
              </p>

              <div class="flex flex-row items-baseline">
                <h4 class="text-xs uppercase font-black text-nl-gray-dark-300">
                  Created at:
                </h4>
                <p class="my-1 ml-2 text-sm text-white">
                  {new Date(
                    props.images?.[props.index()].createdAt || "",
                  ).toLocaleDateString("se-SV")}{" "}
                  |{" "}
                  {new Date(
                    props.images?.[props.index()].createdAt || "",
                  ).toLocaleTimeString("se-SV")}
                </p>
              </div>
              <div class="flex flex-row items-baseline">
                <h4 class="text-xs uppercase font-black text-nl-gray-dark-300">
                  Source:
                </h4>
                <p class="ml-2 text-sm text-white">
                  {props.images?.[props.index()].source.name || ""}
                </p>
              </div>

              <div class="border-t border-b border-gray-400 mt-5 flex-grow min-h-0 overflow-y-auto">
                <For each={props.images?.[props.index()].annotations.items}>
                  {(annotation) => (
                    <Show
                      when={
                        annotation.annotationData.annotationType ===
                        AnnotationTypes.SingleLabel
                      }
                    >
                      <div class="flex flex-col my-3">
                        <h4 class="text-xs uppercase font-black text-nl-gray-dark-300">
                          AnnotationOrigin:
                        </h4>
                        <p class="text-white mb-2">{annotation.originStatus}</p>

                        <For each={props.dataset()?.getDataset.classes.items}>
                          {(label) => (
                            <p
                              class="flex gap-1.5 items-center border border-black border-opacity-20 h-8 w-fit my-1 transition px-2 font-medium text-sm uppercase rounded-md text-nl-gray-200"
                              style={{
                                "background-color":
                                  annotation.annotationData
                                    .singleLabelClassification?.label.id ===
                                  label.id
                                    ? label.color
                                    : "hsl(225,  7%, 65%)",
                              }}
                            >
                              <span class="text-black text-opacity-70">
                                {label.name}
                              </span>
                            </p>
                          )}
                        </For>
                      </div>
                    </Show>
                  )}
                </For>
                <Show
                  when={
                    props.images?.[props.index()].annotations.items?.length ===
                    0
                  }
                >
                  <h4 class="mt-5 text-xs uppercase font-black text-nl-gray-dark-300">
                    No annotations made
                  </h4>
                </Show>
              </div>
              <button
                class="mt-3 px-20 py-2 mx-auto  bg-blue-500 text-white rounded"
                onClick={() => props.setShowModal(false)}
              >
                Close
              </button>
            </div>
          </Show>
        </div>
        <button
          class="-translate-y-1/2 text-white  bg-black/50 m-2 py-5 px-2 rounded-md"
          classList={{ "opacity-40 cursor-default": endOfRightIndex() }}
          onClick={() => {
            if (isIndexValid(props.index() + 1)) {
              props.setCurrentIndex(props.index() + 1);
            }
          }}
        >
          ▶
        </button>
      </div>
    </div>
  );
}

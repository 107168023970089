import { createGraphQLClient } from "../../../helpers/graphql";
import {
  Accessor,
  createEffect,
  createMemo,
  createSignal,
  For,
  Show,
  useContext,
} from "solid-js";
import "@rnwonder/solid-date-picker/dist/style.css";
import utils from "@rnwonder/solid-date-picker/utilities";
import DatePicker, {
  PickerValue,
  TimeValue,
} from "@rnwonder/solid-date-picker";
import TimePicker from "@rnwonder/solid-date-picker/timePicker";
import { UserContext } from "../../../components/UserContext";
import { _GetDatasetQuery } from "../../../helpers/queries";
import { useParams } from "@solidjs/router";
import { useI18n } from "@solid-primitives/i18n";
import { GalleryItems, GetCompleteDataset } from "../../../helpers/types";
import ImageCard from "./ImageCard";
import { SpinnerIcon } from "../../../components/Icons";
import ImageModal from "./ImageModal";

export default function DatasetGallery() {
  const params = useParams();
  const [t] = useI18n();
  const user = useContext(UserContext);
  const client = createGraphQLClient(user?.accessToken);

  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const formatTime24 = (hour: number, minute: number) => {
    return `${hour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")}`;
  };

  const [specificDate, setSpecificDate] = createSignal<PickerValue>({
    value: {
      selected: "",
    },
    label: "",
  });

  const [tempSpecificDateTime1, setTempSpecificDateTime1] =
    createSignal<TimeValue>({
      value: { hour: 0o0, minute: 0o0 },
      label: "",
    });
  const [tempSpecificDateTime2, setTempSpecificDateTime2] =
    createSignal<TimeValue>({
      value: { hour: 23, minute: 59 },
      label: "",
    });

  const [specificDateTime1, setSpecificDateTime1] = createSignal<TimeValue>({
    value: { hour: 0o0, minute: 0o0 },
    label: "",
  });
  const [specificDateTime2, setSpecificDateTime2] = createSignal<TimeValue>({
    value: { hour: 23, minute: 59 },
    label: "",
  });

  const [disableSpecificDateReset, setDisableSpecificDateReset] =
    createSignal<boolean>(true);

  const [dateToCompare1, setDateToCompare1] = createSignal<PickerValue>({
    value: {
      selected: "",
    },
    label: "",
  });
  const [dateToCompare2, setDateToCompare2] = createSignal<PickerValue>({
    value: {
      selected: "",
    },
    label: "",
  });

  const [tempDateToCompare1Time, setTempDateToCompare1Time] = createSignal<TimeValue>({
    value: { hour: 0o0, minute: 0o0 },
    label: "",
  });
  const [tempDateToCompare2Time, setTempDateToCompare2Time] = createSignal<TimeValue>({
    value: { hour: 23, minute: 59 },
    label: "",
  });
  const [dateToCompare1Time, setDateToCompare1Time] = createSignal<TimeValue>({
    value: { hour: 0o0, minute: 0o0 },
    label: "",
  });
  const [dateToCompare2Time, setDateToCompare2Time] = createSignal<TimeValue>({
    value: { hour: 23, minute: 59 },
    label: "",
  });
  const [disableBetweenDatesReset, setDisableBetweenDatesReset] =
    createSignal<boolean>(true);

  const [showModal, setShowModal] = createSignal<boolean>(false);
  const [currentPosition, setCurrentPosition] = createSignal<number>(0);

  const [gallery, setGallery] = createSignal<GalleryItems>();
  const [datasetList, setDatasetList] = createSignal<GetCompleteDataset>();

  const [dataset, { refetch: refetchDataset }] = client<GetCompleteDataset>(
    _GetDatasetQuery,
    {
      datasetId: params.id.trim(),
    },
  );

  const groupedGallery: Accessor<Record<string, GalleryItems>> = createMemo(
    () => {
      const galleryList = [...(gallery() || [])];

      galleryList.sort((a, b) => {
        const dateA = new Date(a.createdAt).getTime();
        const dateB = new Date(b.createdAt).getTime();
        if (isNaN(dateA) || isNaN(dateB)) return 0;
        return dateA - dateB;
      });

      const specificDateStartHour = specificDateTime1().value.hour || 0;
      const specificDateStartMin = specificDateTime1().value.minute || 0;
      const specificDateEndHour = specificDateTime2().value.hour || 23;
      const specificDateEndMin = specificDateTime2().value.minute || 59;

      const specDate = new Date(specificDate().value.selected || NaN).getTime();

      let specificDateStartTime = specDate ? new Date(specDate) : null;
      let specificDateEndTime = specDate ? new Date(specDate) : null;
      console.log("Start: " + specificDateStartTime);
      console.log("End: " + specificDateEndTime);

      if (specificDateStartTime !== null) {
        specificDateStartTime.setHours(
          specificDateStartHour,
          specificDateStartMin,
        );
        console.log("Spec:" + specificDateStartTime);
      }

      if (specificDateEndTime !== null) {
        specificDateEndTime.setHours(
          specificDateEndHour,
          specificDateEndMin,
          59,
          999,
        );
        console.log("Spec: " + specificDateEndTime);
      }

      const startHour = dateToCompare1Time().value.hour || 0;
      const startMin = dateToCompare1Time().value.minute || 0;
      const endHour = dateToCompare2Time().value.hour || 23;
      const endMin = dateToCompare2Time().value.minute || 59;

      const startDate = new Date(dateToCompare1().value.selected || NaN).getTime();
      const endDate = new Date(dateToCompare2().value.selected || NaN).getTime();

      let startDateTime = startDate ? new Date(startDate) : null;
      let endDateTime = endDate ? new Date(endDate) : null;

      if (startDateTime) {
        startDateTime.setHours(startHour, startMin);
        console.log(startDateTime);
      }
      if (endDateTime) {
        endDateTime.setHours(endHour, endMin, 59, 999);
        console.log(endDateTime);
      }

      const filteredGallery = galleryList.filter((image) => {
        const imageDate = new Date(image.createdAt).getTime();

        if (specificDateStartTime !== null && specificDateEndTime !== null) {
          return (
            imageDate >= specificDateStartTime.getTime() &&
            imageDate <= specificDateEndTime.getTime()
          );
        }

        if (startDateTime !== null && endDateTime !== null) {
          return (
            imageDate >= startDateTime.getTime() &&
            imageDate <= endDateTime.getTime()
          );
        }
        if (endDateTime !== null) {
          return imageDate <= endDateTime.getTime();
        }
        if (startDateTime !== null) {
          return imageDate >= startDateTime.getTime();
        }
        return true;
      });

      return filteredGallery.reduce(
        (groups: Record<string, GalleryItems>, image) => {
          const dateKey = new Date(image.createdAt).toLocaleDateString();

          if (!groups[dateKey]) groups[dateKey] = [];
          groups[dateKey].push(image);
          return groups;
        },
        {},
      );
    },
  );

  createEffect(() => {
    if (!dataset.loading && !dataset.error && dataset()) {
      console.log("Dataset was fetched");
      setGallery(dataset()?.getDataset.images.items);
      setDatasetList(dataset());
    }
    if (dataset.error) {
      console.log("Error: " + dataset.error);
    }
  });

  createEffect(() => {
    if (
      dateToCompare1().label !== "" ||
      dateToCompare2().label !== "" ||
      dateToCompare1Time().label !== "" ||
      dateToCompare2Time().label !== ""
    ) {
      setDisableBetweenDatesReset(false);
    }
    if (
      dateToCompare1().label === "" &&
      dateToCompare2().label === "" &&
      dateToCompare1Time().label === "" &&
      dateToCompare2Time().label === ""
    ) {
      setDisableBetweenDatesReset(true);
    }
  });

  createEffect(() => {
    if (
      specificDate().label !== "" ||
      specificDateTime1().label !== "" ||
      specificDateTime2().label !== ""
    ) {
      setDisableSpecificDateReset(false);
    }
    if (
      specificDate().label === "" &&
      specificDateTime1().label === "" &&
      specificDateTime2().label === ""
    ) {
      setDisableSpecificDateReset(true);
    }
  });

  const setPosition = (id: string) => {
    const index = sortedImageList()?.findIndex((image) => image.id === id);
    if (index === -1 || index === undefined) {
      console.error("Image not found in sortedImageList.");
      return;
    }
    console.log(index + ": " + id);
    setCurrentPosition(index);
  };

  const resetSpecificDate = () => {
    if (!disableSpecificDateReset()) {
      setTempSpecificDateTime1({
        value: { hour: 0o0, minute: 0o0 },
        label: "",
      });
      setTempSpecificDateTime2({
        value: { hour: 23, minute: 59 },
        label: "",
      });
      setSpecificDate({
        value: { selected: "" },
        label: "",
      });
      setSpecificDateTime1({
        value: { hour: 0o0, minute: 0o0 },
        label: "",
      });
      setSpecificDateTime2({
        value: { hour: 23, minute: 59 },
        label: "",
      });
    }
  };

  const resetBetweenDates = () => {
    if (!disableBetweenDatesReset()) {
      setTempDateToCompare1Time({
        value: { hour: 0o0, minute: 0o0 },
        label: "",
      });
      setTempDateToCompare2Time({
        value: { hour: 23, minute: 59 },
        label: "",
      });
      setDateToCompare1({ value: { selected: "" }, label: "" });
      setDateToCompare2({ value: { selected: "" }, label: "" });
      setDateToCompare1Time({
        value: { hour: 0o0, minute: 0o0 },
        label: "",
      });
      setDateToCompare2Time({
        value: { hour: 23, minute: 59 },
        label: "",
      });
    }
  };

  const sortedImageList = () => {
    return gallery()?.sort((a, b) => {
      if (a.createdAt < b.createdAt) {
        return -1;
      } else if (a.createdAt > b.createdAt) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  return (
    <div class="min-min-h-screen nl-gradient">
      <div class="py-10">
        <header>
          <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex flex-row items-baseline ">
            <h1 class="text-3xl font-bold leading-tight tracking-tight text-gray-900">
              {t("gallery", {}, "Gallery")}
            </h1>
            <Show when={dataset()?.getDataset.name !== undefined}>
              <h2 class="ml-1 text-2xl font-bold leading-tight tracking-tight text-gray-900">
                ({dataset()?.getDataset.name})
              </h2>
            </Show>
          </div>
        </header>

        <main>
          <div class="mx-auto mt-7 max-w-7xl sm:px-6 lg:px-8">
            <div class="flex flex-col p-5 min-w-full bg-white shadow-sm ring-2 ring-black ring-opacity-5 rounded-sm">
              <Show
                when={gallery() !== undefined}
                fallback={
                  <p>
                    <SpinnerIcon /> Loading...
                  </p>
                }
              >
                <div class="mt-5 p-3 border border-gray-300 bg-gray-100">
                  <div class="m-3">
                    <h2 class="text-lg inline font-medium text-gray-900">
                      Filter by date
                    </h2>
                    <div class="flex items-center">
                      <div class="flex flex-col my-3 w-1/5">
                        <DatePicker
                          value={specificDate}
                          setValue={setSpecificDate}
                          backgroundColor={"rgb(165, 165, 165)"}
                          maxDate={utils().getToday()}
                          weekEndDayTextColor={"rgb(148, 211, 162)"}
                          shouldCloseOnSelect
                          placeholder="Specific date"
                          onChange={() => resetBetweenDates()}
                          inputClass="date-picker-input-css"
                        />
                      </div>
                      <button
                        class="ml-3 mt-1 mb-1 px-5 py-1 rounded-sm bg-blue-400 text-white w-fit h-fit"
                        classList={{
                          "opacity-50 hover:cursor-default pointer-events-none":
                            disableSpecificDateReset(),
                        }}
                        onClick={() => resetSpecificDate()}
                      >
                        Reset
                      </button>
                    </div>
                    <div
                      class="flex items-center justify-between w-1/5 pointer-event-none"
                      classList={{
                        "pointer-event-block": !specificDate().value.selected,
                      }}
                    >
                      <TimePicker
                        value={specificDateTime1}
                        setValue={setTempSpecificDateTime1}
                        allowedView={["hour", "minute"]}
                        hideTopArea={true}
                        shouldCloseOnSelect={true}
                        inputClass="time-picker-input-css"
                        placeholder="00:00"
                        onChange={() => {
                          resetBetweenDates();
                        }}
                        onClose={() => {
                          let formattedTime = tempSpecificDateTime1();
                          formattedTime = {
                            value: formattedTime.value,
                            label: formatTime24(
                              formattedTime.value.hour || 0,
                              formattedTime.value.minute || 0,
                            ),
                          };
                          setSpecificDateTime1(formattedTime);
                        }}
                      />
                      <span> &ndash; </span>
                      <TimePicker
                        value={specificDateTime2}
                        setValue={setTempSpecificDateTime2}
                        allowedView={["hour", "minute"]}
                        hideTopArea={true}
                        shouldCloseOnSelect={true}
                        inputClass="time-picker-input-css"
                        placeholder="23:59"
                        onChange={() => resetBetweenDates()}
                        onClose={() => {
                          let formattedTime = tempSpecificDateTime2();
                          formattedTime = {
                            value: formattedTime.value,
                            label: formatTime24(
                              formattedTime.value.hour || 0,
                              formattedTime.value.minute || 0,
                            ),
                          };
                          setSpecificDateTime2(formattedTime);
                        }}
                      />
                    </div>
                  </div>
                  <div class="m-3 mt-5">
                    <h2 class="text-lg inline font-medium text-gray-900">
                      Between dates
                    </h2>
                    <div class="flex my-3">
                      <div class="flex flex-col w-1/5">
                        <DatePicker
                          value={dateToCompare1}
                          setValue={setDateToCompare1}
                          backgroundColor={"rgb(165, 165, 165)"}
                          weekEndDayTextColor={"rgb(148, 211, 162)"}
                          inputClass="date-picker-input-css"
                          placeholder="Start date"
                          shouldCloseOnSelect
                          onChange={() => resetSpecificDate()}
                        />
                        <div
                          classList={{
                            "pointer-event-block": !dateToCompare1().value.selected,
                          }}
                        >
                          <TimePicker
                            value={dateToCompare1Time}
                            setValue={setTempDateToCompare1Time}
                            allowedView={["hour", "minute"]}
                            hideTopArea={true}
                            shouldCloseOnSelect={true}
                            inputClass="time-picker-input-css"
                            placeholder="00:00"
                            onChange={() => resetSpecificDate()}
                            onClose={() => {
                              let formattedTime = tempDateToCompare1Time();
                              formattedTime = {
                                value: formattedTime.value,
                                label: formatTime24(
                                  formattedTime.value.hour || 0,
                                  formattedTime.value.minute || 0,
                                ),
                              };
                              setDateToCompare1Time(formattedTime);
                            }}
                          />
                        </div>
                      </div>
                      <div class="flex flex-col ml-1 w-1/5">
                        <DatePicker
                          value={dateToCompare2}
                          setValue={setDateToCompare2}
                          backgroundColor={"rgb(165, 165, 165)"}
                          maxDate={utils().getToday()}
                          weekEndDayTextColor={"rgb(148, 211, 162)"}
                          shouldCloseOnSelect
                          inputClass="date-picker-input-css"
                          placeholder="End date"
                          onChange={() => resetSpecificDate()}
                        />
                        <div
                          classList={{
                            "pointer-event-block": !dateToCompare2().value.selected,
                          }}
                        >
                          <TimePicker
                            value={dateToCompare2Time}
                            setValue={setTempDateToCompare2Time}
                            allowedView={["hour", "minute"]}
                            hideTopArea={true}
                            shouldCloseOnSelect={true}
                            inputClass="time-picker-input-css"
                            placeholder="23:59"
                            onChange={() => resetSpecificDate()}
                            onClose={() => {
                              let formattedTime = tempDateToCompare2Time();
                              formattedTime = {
                                value: formattedTime.value,
                                label: formatTime24(
                                  formattedTime.value.hour || 0,
                                  formattedTime.value.minute || 0,
                                ),
                              };
                              setDateToCompare2Time(formattedTime);
                            }}
                          />
                        </div>
                      </div>
                      <button
                        class="ml-3 mt-1 mb-1 px-5 py-1 rounded-sm bg-blue-400 text-white w-fit h-fit"
                        classList={{
                          "opacity-50 hover:cursor-default pointer-events-none":
                            disableBetweenDatesReset(),
                        }}
                        onClick={() => {
                          resetBetweenDates();
                        }}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>

                <For each={Object.entries(groupedGallery())}>
                  {([date, images]) => (
                    <div>
                      <div class="mt-5 p-3 border-b border-gray-100 backdrop-blur bg-gray-100 shadow-sm backdrop-filter text-left">
                        <h2 class="text-lg inline font-medium text-gray-900 p-1">
                          {new Intl.DateTimeFormat("en-US", {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                          }).format(new Date(date))}{" "}
                          | {dayNames[new Date(date).getDay()]}
                        </h2>
                      </div>
                      <div class="grid grid-cols-6 gap-4 p-5 bg-gray-50 shadow-sm">
                        <For each={images as readonly any[] | undefined}>
                          {(image, index) => {
                            const [shouldRender, setShouldRender] =
                              createSignal(false);
                            setTimeout(
                              () => setShouldRender(true),
                              index() * 100,
                            );

                            return (
                              <>
                                {shouldRender() && (
                                  <ImageCard
                                    image={image}
                                    setShowModal={setShowModal}
                                    setPosition={setPosition}
                                  />
                                )}
                              </>
                            );
                          }}
                        </For>
                      </div>
                    </div>
                  )}
                </For>
              </Show>
            </div>
          </div>
          <Show when={showModal()}>
            <ImageModal
              setShowModal={setShowModal}
              index={currentPosition}
              setCurrentIndex={setCurrentPosition}
              images={sortedImageList()}
              dataset={datasetList}
            />
          </Show>
        </main>
      </div>
    </div>
  );
}
